module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3d8df60ae78079e5e1bf9393256a9a2e"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Inter","file":"https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;900&display=swap\" rel=\"stylesheet"},{"name":"Prociono","file":"https://fonts.googleapis.com/css2?family=Prociono:wght@400&display=swap\" rel=\"stylesheet"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-ZLKGF1ECPJ"],"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"delayOnRouteUpdate":0,"origin":"https://www.googletagmanager.com"},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
